//FONT SIZE
$basicFontSize: 16px;

//TYPE
$font-family-map: (
  base: #{'Heebo',
  sans-serif}
);

$font-size-map: (
  base: rem(16px),
  xs: rem(12px),
  s: rem(14px),
  b: rem(22px),
  b-mobile: rem(16px)
);

$line-height-map: (
  base: rem(30px),
  xs: rem(18px),
  s: rem(24px),
  b: rem(36px),
  b-mobile: rem(30px)
);

$heading-font-size-map: (
  h1: rem(70px),
  h2: rem(42px),
  h3: rem(24px),
  h4: rem(18px),
  h5: rem(12px)
);

$heading-line-height-map: (
  h1: rem(72px),
  h2: rem(48px),
  h3: rem(30px),
  h4: rem(24px),
  h5: rem(18px)
);

@function font-size($size: base) {
  $fetched-value: map-get($font-size-map, $size);

  @if $fetched-value != null {
    @return $fetched-value;
  } @else {
    @error 'Font size `#{$size}` not found. Available font sizes: #{available-names($font-size-map)}';
  }
}
@function line-height($size: base) {
  $fetched-value: map-get($line-height-map, $size);

  @if $fetched-value != null {
    @return $fetched-value;
  } @else {
    @error 'Font size `#{$size}` not found. Available font sizes: #{available-names($font-size-map)}';
  }
}

@function font-family($family: base) {
  $fetched-value: map-get($font-family-map, $family);

  @if $fetched-value != null {
    @return $fetched-value;
  } @else {
    @error 'Font family `#{$family}` not found. Available font families: #{available-names($font-family-map)}';
  }
}

@function heading-font-size($heading: h3) {
  $fetched-value: map-get($heading-font-size-map, $heading);

  @if $fetched-value != null {
    @return $fetched-value;
  } @else {
    @error 'Heading Font size `#{$heading}` not found. Available heading font sizes: #{available-names($heading-font-size-map)}';
  }
}
@function heading-line-height($heading: h3) {
  $fetched-value: map-get($heading-line-height-map, $heading);

  @if $fetched-value != null {
    @return $fetched-value;
  } @else {
    @error 'Heading line height `#{$heading}` not found. Available heading line heights: #{available-names($heading-line-height-map)}';
  }
}
